import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

// Sentry.init({
//   dsn: "https://133fc41e1e45ef6a38a2c3e7a9b5e244@o4507344157278208.ingest.us.sentry.io/4507344157474816",
//   tracesSampleRate: 1,

//   integrations: [
//     Sentry.browserTracingIntegration({
//       useEffect,
//       useLocation,
//       useMatches,
//     }),
//   ],
// });

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
